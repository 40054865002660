<template>
  <nav class="bg-white">
    <div class="mx-auto w-full px-2 sm:px-6 lg:px-8 shadow-md">
      <div class="relative flex h-16 items-center justify-between">
        <div class="sm:hidden">
          <!-- Mobile menu button-->
          <div class="dropdown">
            <div tabindex="0" role="button" class="btn btn-outline min-h-fit h-auto py-2.5 btn-primary m-1 ">About
            </div>
            <ul tabindex="0"
              class="dropdown-content z-[100] menu p-2 shadow bg-white border border-primary rounded-box w-max">
              <li>
                <RouterLink active-class="bg-primary text-white" class="btn btn-ghost hover:bg-primary hover:text-white"
                  :to="{ name: '/' }">
                  <a>Home</a>
                </RouterLink>
              </li>
              <li>
                <RouterLink active-class="bg-primary text-white" class="btn btn-ghost hover:bg-primary hover:text-white"
                  :to="{ name: '/about' }">
                  <a>About</a>
                </RouterLink>
              </li>
              <li>
                <RouterLink active-class="bg-primary text-white" class="btn btn-ghost hover:bg-primary hover:text-white"
                  :to="{ name: '/contact' }">
                  <a>Contact</a>
                </RouterLink>
              </li>
              <Permission admin>
                <li>
                  <RouterLink active-class="bg-primary text-white"
                    class="btn btn-ghost hover:bg-primary hover:text-white" :to="{ name: '/admin' }"> Admin
                  </RouterLink>
                </li>
              </Permission>
              <li>
                <a data-umami-event="follow on instagram" target="_blank"
                  href="https://instagram.com/funday_sheffield?igshid=ZDdkNTZiNTM="
                  class="btn btn-ghost hover:bg-primary hover:text-white">
                  <img class="h-8 w-8"
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/2048px-Instagram_logo_2016.svg.png" />
                </a>
              </li>
            </ul>
          </div>

        </div>
        <div class="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
          <div class="flex flex-shrink-0 items-center">
            <RouterLink :to="{ name: '/' }">
              <img class="block h-12 w-auto lg:hidden" src="/logo.svg" alt="Your Company">
              <img class="hidden h-12 w-auto lg:block" src="/logo.svg" alt="Your Company">
            </RouterLink>
          </div>
          <div class="hidden ml-6 sm:block">
            <div class="flex space-x-4 h-full items-center">
              <NavLinks size="sm" />
            </div>
          </div>
        </div>
        <a href="https://play.google.com/store/apps/details?id=com.fundaysheffield.twa" target="_blank">
          <button class="btn btn-outline min-h-fit h-auto py-2.5 btn-primary m-1" outlined size="small">Use App</button>
        </a>

        <!--  <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
          <Login />
        </div> -->
      </div>
    </div>


  </nav>


  <ConfirmDialog />
  <Toast />

  <main class="mb-10">
    <Suspense>
      <RouterView v-slot="{ Component }">
        <transition name="route" mode="out-in">
          <component :is="Component"></component>
        </transition>
      </RouterView>
      <template #fallback>
        Loading...
      </template>
    </Suspense>




  </main>




  <TheFooter class="mb-16" />

  <div
    class="fixed bottom-0 left-0 z-50 w-full h-16 bg-white border-t border-gray-200 dark:bg-gray-700 dark:border-gray-600">
    <div class="grid h-full max-w-lg grid-cols-4 mx-auto font-medium text-gray-600">
      <RouterLink active-class="text-primary bg-primary/15"
        class="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group"
        :to="{ name: '/' }">
        <Icon class="h-5 w-5 mb-2 group-hover:text-primary" icon="heroicons:home-solid" />
        <span class="text-sm  group-hover:text-primary ">Activities</span>
      </RouterLink>
      <RouterLink active-class="text-primary bg-primary/15"
        class="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group"
        :to="{ name: '/playgroups' }">
        <Icon class="h-5 w-5 mb-2 group-hover:text-primary" icon="fa:group" />
        <span class="text-sm  group-hover:text-primary ">Playgroups</span>
      </RouterLink>

      <Login />

      <a active-class="text-primary bg-primary/15"
        class="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group"
        href="https://buy.stripe.com/fZe02Zaw5fCY6QM6op">
        <Icon class="h-5 w-5 mb-2 group-hover:text-primary" icon="simple-icons:buymeacoffee" />
        <span class="text-sm  group-hover:text-primary ">Support</span>
      </a>

    </div>
  </div>
</template>


<script setup lang="ts">
import { onAuthStateChanged, type User } from 'firebase/auth';
import { auth } from './firebase';
import { useUserStore } from './stores/userStore';
import Login from '@/components/Login.vue';
const mobileMenuOpen = ref(false);
const store = useUserStore();
import NavLinks from './components/NavLinks.vue';
import Button from 'primevue/button';
import ConfirmDialog from 'primevue/confirmdialog';
import Toast from 'primevue/toast';
import { useConfirm } from "primevue/useconfirm";
import TheFooter from './components/TheFooter.vue';
import { Icon } from '@iconify/vue';
//@ts-ignore
import { registerSW } from 'virtual:pwa-register'

onAuthStateChanged(auth, (user) => {
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User
    store.user = JSON.parse(JSON.stringify(user)) as User;
    //setAnalyticsCollectionEnabled(analytics, false);
    // ...

    userStore.loadUserFavourites();
  } else {
    store.user = null;
    // User is signed out
    // ...
  }
});

const { loadActivities } = useActivitiesStore();
const { loadEvents } = useEventsStore();
const userStore = useUserStore();
onMounted(async () => {
  loadActivities();
  loadEvents();
})

const route = useRoute();

const router = useRouter();
router.beforeEach((to, from, next) => {
  nextTick(() => {
    document.body.tabIndex = 0
    document.body.focus()
    document.body.tabIndex = -1
  })

  document.title = `Funday Sheffield - ${to.meta.title ?? ''}`;
  next();
});

watch(route, () => {
  setTimeout(() => {
    mobileMenuOpen.value = false;
  }, 100);
}, { deep: true, immediate: true })

import { nextTick, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router/auto';
import { useActivitiesStore } from './stores/activityStore';
import { useEventsStore } from './stores/eventStore';


const confirm = useConfirm();

const updateSW = registerSW({
  immediate: true,
  onNeedRefresh() {
    confirm.require({
      header: "Funday",
      message: `There is a new version of Funday available!`,
      accept: () => updateSW(),
      acceptLabel: "Update",
      rejectClass: "!hidden",

    })

  },
  onOfflineReady() { },
})





</script>

<style>
/* route transitions */
.route-enter-from {
  opacity: 0;
  transform: translateX(100px);
}

.route-enter-active {
  transition: all 0.3s ease-out;
}

.route-leave-to {
  opacity: 0;
  transform: translateX(-100px);
}

.route-leave-active {
  transition: all 0.3s ease-in;
}
</style>
