<template>
  <div
    class="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group cursor-pointer"
    @click="(e) => !loggedIn ? loginModalOpen = true : toggle(e)">
    <div>
      <Icon v-if="!loggedIn" class="h-5 w-5 mb-2 group-hover:text-primary" icon="fa6-solid:circle-user" />
      <Avatar class="h-5 w-5 !mx-0 " v-if="userStore.user && userStore.user.photoURL" :image="userStore.user?.photoURL"
        shape="circle" />
      <Menu ref="menu" id="overlay_menu" :model="items" :popup="true" />
    </div>
    <span class="text-sm text-gray-500 dark:text-gray-400 group-hover:text-primary ">
      {{ loggedIn ? 'Profile' : 'Sign in' }}
    </span>
  </div>


  <PDialog v-model:visible="loginModalOpen" modal header="Sign in" class="sm:max-w-lg">

    <PButton outlined class="w-full justify-center" @click="googleLogin">
      <Icon class="mr-2" icon="fa-brands:google" />
      Sign in with Google
    </PButton>

    <small class="text-xs text-gray-500">Last Updated : {{ version }}</small>
  </PDialog>

</template>

<script setup lang="ts">
import { useUserStore } from '@/stores/userStore';
import { computed, ref } from 'vue';
import { auth } from '@/firebase';
import PDialog from 'primevue/dialog';
import PButton from 'primevue/button';
import Menu from 'primevue/menu'
import Avatar from 'primevue/avatar';
import dayjs from 'dayjs';
import { Icon } from '@iconify/vue';
const version = dayjs(__APP_VERSION__).format('YYYY-MM-DD HH:mm');
const userStore = useUserStore();
const loginModalOpen = ref(false);

import {
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  getAuth
} from "firebase/auth";
import type { MenuItem } from 'primevue/menuitem';


const menu = ref<Menu>();
const toggle = (event: Event) => {
  if (menu.value) {
    menu.value.toggle(event);
  }
};

const items = ref<MenuItem[]>([
  /*   { label: 'Profile', icon: 'i-heroicons-adjustments-horizontal-solid', }, */
  { label: 'Logout', icon: 'i-heroicons-arrow-left-on-rectangle', command: () => logout() },
  { label: `Last updated ${version}`, },
]);
const provider = new GoogleAuthProvider();


async function googleLogin() {
  await signInWithPopup(getAuth(), provider);
  loginModalOpen.value = false;
}

const loggedIn = computed(() => userStore.user != null);

function logout() {
  signOut(auth).then(() => {
    // Sign-out successful.
  }).catch((error) => {
    // An error happened.
  });
}
</script>
